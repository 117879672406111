@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

a {
    position: relative;
}

::selection {
    @apply bg-magenta;
    @apply text-textOnMagenta;
    text-shadow: 2px 2px 2px #991B53;
}

.bg-magenta, .portfolio-details-container {
    span::selection, p::selection, a::selection, h1::selection, h2::selection, h3::selection, h4::selection, li::selection, u::selection {
        @apply bg-textOnMagenta;
        @apply text-magenta;
        text-shadow: 2px 2px 2px #D1D5DB;
    }
}

.hover\:underline-black::before, .hover\:underline-white::before {
    content: '';
    @apply absolute;
    @apply bottom-0;
    @apply right-0;
    @apply w-0;
    height: 2px;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.hover\:underline-white::before {
    @apply bg-textOnMagenta;
}
.hover\:underline-black::before {
    @apply bg-black;
}

@media (hover: hover) and (pointer: fine) {
    .hover\:underline-black:hover::before, .hover\:underline-white:hover::before {
        @apply left-0;
        @apply right-auto;
        @apply w-full;
    }
}